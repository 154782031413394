@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');



html {
    padding: 0;
    margin: 0;
    position: relative;
    min-height: 100%;
    font-size: 1rem;
    /* background-color: #fafbfc; */
    /* background-color: #f2fdff; */

    background-color:  #E3F3FD; /*EFF6FA*/

}

body {
    background-image: none;
}

.home-bg {
    background-image: url('https://ik.imagekit.io/makers/images/diamonds.png');
    background-repeat: no-repeat;
    /* background-size: contain; */

}
.logo {
    width: 200px
}

.home-section {
    width: 100%;
    min-height:400px;
    background-color: green;
}

body.modal-open {
    overflow: hidden;
}

.w-320 {
    width:320px;
}

.w-200 {
    width: 170px;
}
.comments {
}

    .comments ul {
        padding-left: 0;
    }

    .comments li {
        margin-left: 0px;
        list-style-type: none;
        padding: 0px 5px 0 50px;
        /* border-left: 1px solid #eee; */
    }



